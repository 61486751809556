.loading-div {
    background-color: #272229;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  
  .prevent-select:hover{
    color: rgb(84, 84, 237);
  }